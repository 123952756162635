import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { Series } from '@/models/series';
import { GetSeriesApiResponse } from '@/types/apis/series';

const SERVICE_PATH = '/car-series';

export const getSeries = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data } = await publicApi<GetSeriesApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    const series = data.data.map((item) => ({
      id: item.id,
      name: item.name,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      publishedAt: new Date(item.publishedAt),
    })) as Series[];

    return series;
  } catch (error) {
    return null;
  }
};
