import * as React from 'react';
import { CalendarBlank } from '@phosphor-icons/react/dist/ssr';
import { format } from 'date-fns';
import { Avatar } from '@nextui-org/avatar';

import { Thumbnail } from '..';

import { Article } from '@/models/article';

interface ArticleThumbnailProps extends Partial<React.ComponentProps<typeof Thumbnail>> {
  article: Article;
  imageSize?: 'original' | 'large' | 'medium' | 'small' | 'thumbnail';
}

const ArticleThumbnail: React.FC<ArticleThumbnailProps> = ({
  article,
  imageSize = 'medium',
  ...others
}) => {
  return (
    <Thumbnail
      {...others}
      href={`/${article.type?.slug}/${article.slug}`}
      imageUrl={
        (imageSize === 'original'
          ? article.cover?.url
          : (article.cover?.formats?.[imageSize]?.url ?? '')) ?? ''
      }
      metadata={[
        {
          key: 'createdBy',
          value: article.author ? (
            <div className='flex flex-row items-center gap-2'>
              <Avatar className='h-7 w-7' src={article.author?.avatar?.url} />
              <span>{article.author?.name}</span>
            </div>
          ) : null,
        },
        {
          key: 'createdAt',
          value: (
            <div className='flex flex-row items-center gap-2'>
              <CalendarBlank className='w-5' />
              {format(article.publishedAt, 'd MMM yyyy').toUpperCase()}
            </div>
          ),
        },
      ]}
      tags={article.categories?.map(({ id, name }) => ({ id, label: name }))}
      title={article.title}
    />
  );
};

export default ArticleThumbnail;
