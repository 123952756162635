import * as React from 'react';
import { cn } from '@nextui-org/theme';
import { Button } from '@nextui-org/button';
import Link from 'next/link';

import { FallbackImage, ThumbnailTags } from '@/components';
import { Article } from '@/models/article';
import { body, heading } from '@/theme/typography';
import { ArrowRightSvg } from '@/assets/icons';

interface FullWidthArticleThumbnailProps {
  article: Article;
  href: string;
  className?: string;
}

const SideArticleThumbnail: React.FC<FullWidthArticleThumbnailProps> = ({
  article,
  href,
  className,
}) => (
  <div className={cn('flex flex-col lg:flex-row', className)}>
    <div className='flex flex-col items-start justify-center gap-6 bg-white-75 p-12 lg:flex-[2]'>
      <hgroup className='flex flex-col gap-2'>
        <ThumbnailTags tags={article.categories?.map(({ id, name }) => ({ id, label: name }))} />

        <Link href={href}>
          <h3 className={heading({ size: 'h4' })}>{article.title}</h3>
        </Link>
      </hgroup>

      <p className={cn(body(), 'text-gray-600')}>{article.description}</p>

      <Link href={href}>
        <Button
          className='border-neutral-black hover:bg-neutral-black hover:text-neutral-white hover:!opacity-100'
          endContent={<ArrowRightSvg className='w-6' />}
          radius='none'
          size='lg'
          variant='bordered'
        >
          Read Now
        </Button>
      </Link>
    </div>

    <Link className='lg:flex-[4]' href={href}>
      <FallbackImage
        alt={article.title}
        className='aspect-video h-full w-full object-cover'
        height={560}
        src={article.cover?.formats?.medium?.url ?? ''}
        width={1000}
      />
    </Link>
  </div>
);

export default SideArticleThumbnail;
