'use client';

import Image, { ImageProps } from 'next/image';
import * as React from 'react';

const FallbackImage: React.FC<ImageProps> = ({ ...others }) => {
  const [src, setSrc] = React.useState(others.src);

  React.useEffect(() => setSrc(others.src), [others.src]);

  return <Image {...others} src={src} onError={() => setSrc('/assets/images/image-error.png')} />;
};

export default FallbackImage;
