'use client';

import * as React from 'react';
import { cn } from '@nextui-org/theme';
import { Divider } from '@nextui-org/divider';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import { Button } from '@nextui-org/button';
import { CaretDown } from '@phosphor-icons/react';
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';

import { body, heading } from '@/theme/typography';
import { CommentInput, CommentItem, CommentLoading } from '@/components';
import { useArticleComments } from '@/hooks/data';
import { MessagesSvg } from '@/assets/icons';

interface ArticleCommentProps {
  articleId: number;
  authorId?: number;
}

const RESPOND_SORTS = [
  { key: 'most-relevant', label: 'Most relevant' },
  { key: 'recently', label: 'Recently' },
];

const ArticleComment: React.FC<ArticleCommentProps> = ({ articleId, authorId }) => {
  const { data: session } = useSession();
  const { comments, isLoading, createMutation, updateMutation, deleteMutation } =
    useArticleComments(articleId);

  const [currentSort, setCurrentSort] = React.useState(new Set(['most-relevant']));

  const selectedSort = React.useMemo(
    () => RESPOND_SORTS.find((sort) => currentSort.has(sort.key)),
    [currentSort],
  );

  const handleAppendComment = async (content: string) => {
    await createMutation.mutateAsync(content);
  };

  const handleEditComment = async (commentId: number, content: string) => {
    await updateMutation.mutateAsync(
      { commentId, content },
      {
        onSuccess: () => {
          toast('Your comment updated successfully');
        },
        onError: () => {
          toast.error('Failed to update comment');
        },
      },
    );
  };

  const handleDeleteComment = async (commentId: number) => {
    await deleteMutation.mutateAsync(commentId, {
      onSuccess: () => {
        toast('Your comment deleted successfully');
      },
      onError: () => {
        toast.error('Failed to delete comment');
      },
    });
  };

  return (
    <section className='flex flex-col gap-8' id='comments'>
      <div className='flex flex-col gap-4'>
        <h2 className={heading({ size: 'h5' })}>Responds ({0})</h2>

        {!!session && (
          <CommentInput
            avatarUrl={session?.user.avatar?.url ?? ''}
            loading={createMutation.isPending}
            onSend={handleAppendComment}
          />
        )}
      </div>

      <Dropdown>
        <DropdownTrigger>
          <Button
            className={cn(body({ weight: 'medium' }), '-ml-2 self-start p-2 text-gray-700')}
            endContent={<CaretDown className='w-5' weight='fill' />}
            variant='light'
          >
            {selectedSort?.label}
          </Button>
        </DropdownTrigger>

        <DropdownMenu
          aria-label='Sort comments'
          items={RESPOND_SORTS}
          selectedKeys={currentSort}
          selectionMode='single'
          onSelectionChange={(key) => setCurrentSort(key as Set<string>)}
        >
          {(item) => <DropdownItem key={item.key}>{item.label}</DropdownItem>}
        </DropdownMenu>
      </Dropdown>

      <div className='flex flex-col items-start gap-6'>
        {isLoading &&
          Array.from({ length: 3 }, (_, index) => index).map((_, index) => (
            <React.Fragment key={index}>
              <CommentLoading key={index} className='w-full' />
              {index !== 2 && <Divider orientation='horizontal' />}
            </React.Fragment>
          ))}

        {!isLoading &&
          comments?.reverse()?.map((comment, index) => (
            <React.Fragment key={comment.id}>
              <CommentItem
                comment={comment}
                isAuthor={comment.author.id === authorId}
                isMe={comment.author.id === session?.user.id}
                loading={deleteMutation.isPending}
                onDelete={handleDeleteComment}
                onEdit={handleEditComment}
                onReact={() => {}}
              />

              {index !== comments.length - 1 && <Divider orientation='horizontal' />}
            </React.Fragment>
          ))}

        {!isLoading && comments?.length === 0 && (
          <div className='flex w-full flex-col items-center gap-6'>
            <MessagesSvg className='w-32 text-gray-200' />

            <p className={cn(body({ weight: 'medium' }), 'text-center text-gray-800')}>
              There are currently no responses for this article.
              <br />
              Be the first to respond.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default ArticleComment;
