import { AxiosRequestConfig } from 'axios';

import { authApi, publicApi } from '@/config/api';
import { CommentThread } from '@/models/comment';
import {
  CreateCommentInArticleApiRequest,
  GetCommentsByArticleApiResponse,
  UpdateCommentInArticleApiRequest,
} from '@/types/apis/comment';

const SERVICE_PATH = '/comments/api::article.article';

export const getCommentsByArticleId = async (
  articleId: number,
  params?: AxiosRequestConfig['params'],
) => {
  const {
    data: { data },
  } = await publicApi<GetCommentsByArticleApiResponse>({
    method: 'GET',
    url: `${SERVICE_PATH}:${articleId}/flat`,
    params,
  });

  const comments = data.map(
    (comment) =>
      ({
        ...comment,
        ...(comment.author
          ? {
              author: {
                ...comment.author,
                ...(comment.author.avatar
                  ? {
                      avatar: {
                        ...comment.author.avatar,
                        createdAt: new Date(comment.author.avatar.createdAt),
                        updatedAt: new Date(comment.author.avatar.updatedAt),
                      },
                    }
                  : {}),
              },
            }
          : {}),
        ...(comment.threadOf
          ? {
              threadOf: {
                ...comment.threadOf,
                author: {
                  ...comment.threadOf.author,
                  id: Number(comment.threadOf.author.id),
                },
                createdAt: new Date(comment.threadOf.createdAt),
                updatedAt: new Date(comment.threadOf.updatedAt),
              },
            }
          : {}),
        createdAt: new Date(comment.createdAt),
        updatedAt: new Date(comment.updatedAt),
      }) as CommentThread,
  );

  return { comments };
};

export const createCommentInArticle = async ({
  articleId,
  data,
  token,
}: {
  articleId: number;
  data: CreateCommentInArticleApiRequest;
  token: string;
}) =>
  await authApi(token)({
    method: 'POST',
    url: `${SERVICE_PATH}:${articleId}`,
    data,
  });

export const updateCommentInArticle = async ({
  articleId,
  commentId,
  data,
  token,
}: {
  articleId: number;
  commentId: number;
  data: UpdateCommentInArticleApiRequest;
  token: string;
}) =>
  await authApi(token)({
    method: 'PUT',
    url: `${SERVICE_PATH}:${articleId}/comment/${commentId}`,
    data,
  });

export const deleteCommentInArticle = async ({
  articleId,
  commentId,
  authorId,
  token,
}: {
  articleId: number;
  commentId: number;
  authorId: number;
  token: string;
}) =>
  await authApi(token)({
    method: 'DELETE',
    url: `${SERVICE_PATH}:${articleId}/comment/${commentId}`,
    params: { authorId },
  });
