'use client';

import { Chip } from '@nextui-org/chip';
import { Listbox, ListboxItem } from '@nextui-org/listbox';
import { cn } from '@nextui-org/theme';
import { Tooltip } from '@nextui-org/tooltip';
import * as React from 'react';

import { caption } from '@/theme/typography';

interface TagsTooltipProps {
  tags: { id: number; label: string }[];
  className?: string;
}

const TagsTooltip: React.FC<TagsTooltipProps> = ({ tags, className }) => {
  return (
    <Tooltip
      showArrow
      classNames={{ content: 'rounded-none p-2' }}
      content={
        <Listbox>
          {tags.map(({ id, label }, index) => (
            <ListboxItem
              key={`tag-${id}`}
              className='rounded-none capitalize'
              showDivider={tags.length - 1 !== index}
            >
              {label}
            </ListboxItem>
          ))}
        </Listbox>
      }
    >
      <Chip
        className={cn('bg-gray-50 uppercase text-gray-800', className)}
        classNames={{ content: caption({ weight: 'medium' }) }}
        radius='none'
        size='sm'
      >
        + {tags.length}
      </Chip>
    </Tooltip>
  );
};

export default TagsTooltip;
