import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSession } from 'next-auth/react';

import {
  createCommentInArticle,
  deleteCommentInArticle,
  getCommentsByArticleId,
  updateCommentInArticle,
} from '@/services/apis/comment';

const PAGE_SIZE = 10;

const useArticleComments = (articleId: number) => {
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const [currentPage] = useState(1);

  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: ['article-comments', articleId, { page: currentPage, pageSize: PAGE_SIZE }],
    queryFn: () =>
      getCommentsByArticleId(articleId, {
        'pagination[page]': currentPage,
        'pagination[pageSize]': PAGE_SIZE,
        'pagination[withCount]': true,
      }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  const createCommentMutation = useMutation({
    mutationFn: async (content: string) => {
      if (!session?.user) throw new Error('User is not logged in');

      await createCommentInArticle({
        articleId,
        data: { content },
        token: session?.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: async ({ commentId, content }: { commentId: number; content: string }) => {
      if (!session?.user) throw new Error('User is not logged in');

      await updateCommentInArticle({
        articleId,
        commentId,
        data: { content },
        token: session?.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: async (commentId: number) => {
      if (!session?.user) throw new Error('User is not logged in');

      await deleteCommentInArticle({
        commentId,
        articleId,
        authorId: session.user.id,
        token: session.token ?? '',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['article-comments', articleId],
      });
    },
  });

  return {
    comments: data?.comments,
    isSuccess,
    isLoading,
    isError,

    // Mutations
    createMutation: createCommentMutation,
    updateMutation: updateCommentMutation,
    deleteMutation: deleteCommentMutation,
  };
};

export default useArticleComments;
