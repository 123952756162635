'use client';

import { Button, ButtonProps } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { body } from '@/theme/typography';
import { BrandGoogleSvg } from '@/assets/icons';

const SignInWithGoogleButton: React.FC<ButtonProps> = ({ ...others }) => {
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') || '/';

  return (
    <Button
      className={cn('border-white-100', body({ weight: 'medium' }))}
      radius='none'
      size='lg'
      startContent={<BrandGoogleSvg className='w-6' />}
      variant='bordered'
      onClick={() => signIn('google', { callbackUrl })}
      {...others}
    >
      Google
    </Button>
  );
};

export default SignInWithGoogleButton;
