import { cn } from '@nextui-org/theme';
import * as React from 'react';

import { FallbackImage } from '..';

import { caption as typographyCaption } from '@/theme/typography';

interface ImageProps {
  imageUrl: string;
  caption?: string | null;
  fullWidth?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ caption, imageUrl, width, height, className }) => {
  return (
    <div className={cn('flex w-full flex-col gap-2', className)}>
      <FallbackImage
        alt={caption ?? 'Image'}
        className='w-full bg-gray-100 object-cover'
        height={height ?? 300}
        src={imageUrl}
        width={width ?? 400}
      />

      <span className={cn(typographyCaption(), 'self-center text-center text-gray-600')}>
        {caption}
      </span>
    </div>
  );
};

export default Image;
