import { cn } from '@nextui-org/theme';
import * as React from 'react';

import { FallbackImage } from '..';

import { caption as typographyCaption } from '@/theme/typography';

interface GalleryProps {
  images: { id: number; url: string }[];
  caption?: string | null;
  fullWidth?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

const Gallery: React.FC<GalleryProps> = ({ caption, images, width, height, className }) => {
  return (
    <div className={cn('flex w-full flex-col gap-2', className)}>
      <div className='grid w-full grid-cols-3 gap-2'>
        {images.map(({ id, url }) => (
          <div key={id}>
            <FallbackImage
              alt={caption ?? 'Image'}
              className='aspect-square h-full w-full bg-gray-100 object-cover'
              height={height ?? 400}
              src={url}
              width={width ?? 400}
            />
          </div>
        ))}
      </div>

      <span className={cn(typographyCaption(), 'self-center text-center text-gray-600')}>
        {caption}
      </span>
    </div>
  );
};

export default Gallery;
