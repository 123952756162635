import { Skeleton } from '@nextui-org/skeleton';
import { cn } from '@nextui-org/theme';
import * as React from 'react';

interface CommentLoadingProps {
  rows?: number;
  className?: string;
}

const CommentLoading: React.FC<CommentLoadingProps> = ({ rows = 3, className }) => {
  return (
    <div className={cn('flex flex-col gap-6', className)}>
      <div className='flex w-full flex-row gap-4'>
        <Skeleton className='h-12 w-12 rounded-full' />

        <div className='flex flex-col gap-2'>
          <Skeleton className='h-6 w-48' />
          <Skeleton className='h-3 w-28' />
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        {Array.from({ length: rows }, (_, index) => index).map((_, index) => (
          <Skeleton key={index} className='h-4 w-full last:w-2/5' />
        ))}
      </div>
    </div>
  );
};

export default CommentLoading;
