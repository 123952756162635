'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import { HandsClapping } from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';

import { humanizeNumber } from '@/utils/number';

interface ReactButtonProps {
  count: number;
  onClick?: () => void;
  className?: string;
}

const ReactButton: React.FC<ReactButtonProps> = ({ count, onClick, className }) => {
  const [reacted, setReacted] = React.useState(false);

  const handleReact = () => {
    setReacted((prev) => !prev);
    onClick?.();
  };

  return (
    <Button
      className={cn(
        'flex w-auto min-w-0 flex-row items-center gap-2 px-2',
        'data-[hover=true]:text-bmw-blue-500',
        { 'text-bmw-blue-500': reacted },
        className,
      )}
      startContent={<HandsClapping size={24} weight={reacted ? 'fill' : 'regular'} />}
      variant='light'
      onClick={handleReact}
    >
      {humanizeNumber(reacted ? count + 1 : count)}
    </Button>
  );
};

export default ReactButton;
