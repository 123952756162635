'use client';

import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { motion } from 'framer-motion';

import { BmwThreeDotsSvg, BmwThreeStrikesSvg } from '@/assets/svgs';
import { heading } from '@/theme/typography';

interface SectionTitleProps {
  title: string | React.ReactNode;
  notation?: 'strike' | 'dot';
  className?: string;
  classNames?: {
    title: string;
    notation?: string;
  };
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  notation = 'strike',
  className,
  classNames,
}) => {
  return (
    <div className={cn('flex flex-row items-center gap-4', className)}>
      {notation === 'strike' && <BmwThreeStrikesSvg className={cn('w-9', classNames?.notation)} />}
      {notation === 'dot' && <BmwThreeDotsSvg className={cn('w-9', classNames?.notation)} />}

      <motion.h2
        initial={{ opacity: 0, x: -10 }}
        transition={{ delay: 0.2, type: 'tween' }}
        viewport={{ once: true }}
        whileInView={{ opacity: 1, x: 0 }}
      >
        <span className={cn(heading({ size: 'h3' }), classNames?.title)}>{title}</span>
      </motion.h2>
    </div>
  );
};

export default SectionTitle;
