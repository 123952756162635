'use client';

import { Button } from '@nextui-org/button';
import { cn } from '@nextui-org/theme';
import {
  BookmarkSimple,
  ChatsCircle,
  Export,
  FacebookLogo,
  LinkedinLogo,
  LinkSimpleHorizontal,
  XLogo,
} from '@phosphor-icons/react/dist/ssr';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';

import { ReactButton } from '..';

interface IReactSectionProps {
  title: string;
  slug: string;
  reactionCount?: number;
  commentCount?: number;
  onReact?: () => void;
  onComment?: () => void;
}

const ReactSection: React.FC<IReactSectionProps> = ({
  title,
  slug,
  reactionCount = 0,
  commentCount = 0,
  onReact,
  onComment,
}) => {
  const handleCopyLink = async (event: React.MouseEvent<HTMLElement>, link: string) => {
    event.stopPropagation();

    try {
      await navigator.clipboard.writeText(link);
      toast('Link copied to clipboard');
    } catch (_error) {
      toast.error('Failed to copy link to clipboard');
    }
  };

  const scrollToComments = () => {
    const comments = document.getElementById('comments');

    if (!comments) return;
    window.scroll({
      top: comments.offsetTop - 128,
      behavior: 'smooth',
    });

    onComment?.();
  };

  return (
    <section className='flex flex-row items-center justify-between gap-6'>
      <div className='-ml-2 flex flex-row items-center gap-2'>
        <ReactButton className='text-gray-600' count={reactionCount} onClick={onReact} />

        <Button
          className={cn('flex w-auto min-w-0 flex-row items-center gap-2 px-2 text-gray-600')}
          startContent={<ChatsCircle size={24} />}
          variant='light'
          onClick={scrollToComments}
        >
          {commentCount}
        </Button>
      </div>

      <div className='flex flex-row items-center gap-2'>
        <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
          <BookmarkSimple size={24} />
        </Button>

        <Dropdown
          showArrow
          classNames={{
            content: 'border-none bg-background rounded-none',
          }}
          offset={10}
          placement='bottom-end'
          radius='sm'
          ria-label='Saved list actions'
        >
          <DropdownTrigger>
            <Button isIconOnly className='text-gray-600' radius='none' variant='light'>
              <Export size={24} />
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            aria-label='Share article'
            items={[
              {
                key: 'copy-link',
                icon: <LinkSimpleHorizontal size={24} />,
                label: 'Copy link',
                onClick: (event: React.MouseEvent<HTMLElement>) =>
                  handleCopyLink(event, `https://bimmerhead.net/guides/${slug}`),
              },
              {
                key: 'x',
                icon: <XLogo size={24} />,
                label: 'Share on X',
                href: `https://twitter.com/intent/tweet?text=${title}&url=https://bimmerhead.net/news/${slug}`,
              },
              {
                key: 'facebook',
                icon: <FacebookLogo size={24} />,
                label: 'Share on Facebook',
                href: `https://www.facebook.com/sharer/sharer.php?u=https://bimmerhead.net/news/${slug}`,
              },
              {
                key: 'linkedin',
                icon: <LinkedinLogo size={24} />,
                label: 'Share on LinkedIn',
                href: `https://www.linkedin.com/shareArticle?mini=true&title=${title}&url=https://bimmerhead.net/news/${slug}`,
              },
            ]}
          >
            {({ key, icon, label, href, onClick }) => (
              <DropdownItem
                key={key}
                aria-label={label}
                startContent={icon}
                variant='light'
                onClick={onClick}
                {...(href
                  ? {
                      as: 'a',
                      href,
                      rel: 'noreferrer',
                      target: '_blank',
                    }
                  : {})}
              >
                {label}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    </section>
  );
};

export default ReactSection;
