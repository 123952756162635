import { useQuery } from '@tanstack/react-query';

import { getCategories } from '@/services/apis/category';

const useCategories = ({ params }: any) => {
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: ['categories', params],
    queryFn: () =>
      getCategories({
        params: {
          'sort[0]': 'createdAt:DESC',
          ...params,
        },
      }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    categories: data,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useCategories;
