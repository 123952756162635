import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, InputProps } from '@nextui-org/input';

interface RHFInputProps extends InputProps {
  name: string;
}

const RHFInput: React.FC<RHFInputProps> = ({ name, ...others }) => {
  const { control } = useFormContext<Record<string, string>>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <Input {...field} {...others} />}
    />
  );
};

export default RHFInput;
