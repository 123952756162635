import { Divider } from '@nextui-org/divider';
import { Skeleton, SkeletonProps } from '@nextui-org/skeleton';
import { cn } from '@nextui-org/theme';
import * as React from 'react';

interface IArticleThumbnailLoadingProps {
  variant?: 'light' | 'dark';
  className?: string;
  classNames?: {
    image?: string;
    title?: string;
    tag?: string;
    tags?: string;
    description?: string;
    createdBy?: string;
    createdAt?: string;
    divider?: string;
    contentWrapper?: string;
    ctaButton?: string;
  };
  imageProps?: SkeletonProps;
}

const ArticleThumbnailLoading: React.FC<IArticleThumbnailLoadingProps> = ({
  variant = 'light',
  className,
  classNames = {},
  imageProps,
}) => {
  return (
    <div
      className={cn(
        'group flex flex-col gap-4',
        { 'text-neutral-white': variant === 'dark' },
        className,
      )}
    >
      <Skeleton {...imageProps} className={cn('aspect-[42/27] w-full', classNames.image)} />

      <div className={cn('flex flex-col gap-4', classNames.contentWrapper)}>
        <div className='flex flex-col gap-2'>
          <Skeleton className='mb-2 h-3 w-20' />
          <Skeleton className='h-6 w-full' />
          <Skeleton className='h-6 w-3/4' />
        </div>

        <div className='my-2 flex flex-row items-center gap-3 text-gray-600'>
          <div className='flex flex-row items-center gap-2'>
            <Skeleton className='h-7 w-7 rounded-full' />
            <Skeleton className='h-3 w-20' />
          </div>

          <Divider
            className={cn('h-3', { 'bg-neutral-white': variant === 'dark' })}
            orientation='vertical'
          />

          <Skeleton className='h-3 w-20' />
        </div>
      </div>
    </div>
  );
};

export default ArticleThumbnailLoading;
