import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { GetCategoriesApiResponse } from '@/types/apis/category';
import { Category } from '@/models/category';

const SERVICE_PATH = '/categories';

export const getCategories = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data: res } = await publicApi<GetCategoriesApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    return res.data.map(
      (category) =>
        ({
          ...category,
          createdAt: new Date(category.createdAt),
          updatedAt: new Date(category.updatedAt),
        }) as Category,
    );
  } catch (error) {
    return null;
  }
};
