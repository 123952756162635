import { cn } from '@nextui-org/theme';
import * as React from 'react';
import { Chip } from '@nextui-org/chip';

import TagsTooltip from './TagsTooltip';

import { caption } from '@/theme/typography';

interface ThumbnailTagsProps {
  tags?: { id: number; label: string }[];
  max?: number;
  className?: string;
  classNames?: {
    tag?: string;
  };
}

const ThumbnailTags: React.FC<ThumbnailTagsProps> = ({
  tags,
  max = 3,
  className,
  classNames = {},
}) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <div className={cn('flex flex-row flex-wrap gap-2', className)}>
      {(tags.length > max ? tags.slice(0, max) : tags).map(({ id, label }) => (
        <Chip
          key={`tag-${id}`}
          className={cn('bg-gray-50 uppercase text-gray-800', classNames.tag)}
          classNames={{ content: caption({ weight: 'medium' }) }}
          radius='none'
          size='sm'
        >
          {label}
        </Chip>
      ))}

      {tags.length > max && <TagsTooltip className={classNames.tag} tags={tags.slice(max)} />}
    </div>
  );
};

export default ThumbnailTags;
