import * as React from 'react';

import ReviewArticleBadge from './ReviewArticleBadge';

import { ArticleThumbnail } from '@/components';

const ReviewArticleThumbnail: React.FC<React.ComponentProps<typeof ArticleThumbnail>> = ({
  article,
  ...others
}) => {
  return (
    <ArticleThumbnail
      article={article}
      {...others}
      badge={<ReviewArticleBadge badge={article.badge} />}
    />
  );
};

export default ReviewArticleThumbnail;
