import * as React from 'react';
import { cn } from '@nextui-org/theme';

import { CarReviewBadgeSvg } from '@/assets/svgs';
import { caption, heading } from '@/theme/typography';

interface CarReviewBadgeProps {
  score: number;
  totalScore?: number;
  className?: string;
  classNames?: {
    badge?: string;
    score?: string;
  };
}

const CarReviewBadge: React.FC<CarReviewBadgeProps> = ({
  score,
  totalScore = 10,
  className,
  classNames,
}) => {
  return (
    <div className={cn('relative max-w-fit', className)}>
      <div
        className={cn(
          'absolute left-1/2 top-2 -translate-x-1/2 text-neutral-white',
          classNames?.score,
        )}
      >
        <span className={cn(heading({ size: 'h4' }))}>
          {Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).format(score)}
        </span>
        <span className={cn(caption())}>/</span>
        <span className={cn(caption())}>{totalScore}</span>
      </div>

      <CarReviewBadgeSvg className={cn('w-[72px]', classNames?.badge)} />
    </div>
  );
};

export default CarReviewBadge;
