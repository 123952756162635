'use client';

import { ImageProps } from 'next/image';
import * as React from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';

import { FallbackImage } from '@/components';

interface ParallaxImageProps extends ImageProps {}

const ParallaxImage: React.FC<ParallaxImageProps> = ({ ...others }) => {
  const container = React.useRef<HTMLDivElement | null>(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start'],
  });

  const y = useTransform(scrollYProgress, [0, 1], ['-10vh', '10vh']);

  return (
    <div
      ref={container}
      className='relative flex aspect-video max-h-[640px] w-full items-center justify-center overflow-hidden'
      style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }}
    >
      <div className='fixed left-0 top-[-10vh] h-[120vh] w-full'>
        <motion.div className='relative h-full w-full' style={{ y }}>
          <FallbackImage className='bg-white-100 object-cover' {...others} />
        </motion.div>
      </div>
    </div>
  );
};

export default ParallaxImage;
