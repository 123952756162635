import { useQuery } from '@tanstack/react-query';

import { getArticles } from '@/services/apis/article';

const useArticles = ({ params }: any) => {
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: ['articles', params],
    queryFn: () => getArticles({ params }),
    staleTime: 10 * 1000,
    select: (data) => data,
  });

  return {
    articles: data,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useArticles;
