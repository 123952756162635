'use client';

import {
  getKeyValue,
  Table as NextUITable,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/table';
import * as React from 'react';
import { cn } from '@nextui-org/theme';

import { paragraph } from '@/theme/typography';

interface SpecsTableProps {
  rows: {
    [key: string]: any;
    key: string | number;
  }[];
  columns: {
    key: string | number;
    label: string;
  }[];
}

const Table: React.FC<SpecsTableProps> = ({ rows, columns }) => {
  return (
    <NextUITable hideHeader removeWrapper aria-label='Example table with dynamic content'>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>

      <TableBody items={rows}>
        {(item) => (
          <TableRow key={item.key} className='border-b data-[odd="true"]:bg-white-100'>
            {(columnKey) => (
              <TableCell className={cn('px-4 py-6', paragraph())}>
                {getKeyValue(item, columnKey)}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </NextUITable>
  );
};

export default Table;
