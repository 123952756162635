import { cn } from '@nextui-org/theme';
import * as React from 'react';

import { heading } from '@/theme/typography';

interface ReportReviewBadgeProps {
  label: string;
  className?: string;
}

const ReportReviewBadge: React.FC<ReportReviewBadgeProps> = ({ label, className }) => {
  return (
    <div
      className={cn(
        'flex flex-row items-stretch',
        'bg-[url(https://storage.googleapis.com/upload-xdata/xdata-file/bmw/data-crawled/75789010-d609-46b7-b9d5-ed3167a39236/report-badge-bg.png)] bg-cover bg-center bg-no-repeat',
        className,
      )}
    >
      <div className='min-h-full w-1 shrink-0 bg-bmw-red-500' />
      <div className='min-h-full w-1 shrink-0 bg-bmw-blue-700' />
      <div className={cn(heading({ size: 'h5' }), 'px-4 py-2 text-neutral-white')}>{label}</div>
    </div>
  );
};

export default ReportReviewBadge;
