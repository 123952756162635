import * as React from 'react';
import { cn } from '@nextui-org/theme';

import { heading } from '@/theme/typography';

interface QuoteProps {
  quote: string;
  quoter?: string | null;
  className?: string;
}

const Quote: React.FC<QuoteProps> = ({ quote, quoter, className }) => {
  return (
    <div className={cn('flex w-full flex-col gap-4 bg-white-75 p-6', className)}>
      <p className={heading({ size: 'h5', weight: 'medium' })}>&ldquo;{quote}&ldquo;</p>

      {quoter && <div className='self-end'>- {quoter}</div>}
    </div>
  );
};

export default Quote;
