'use client';

import { Button } from '@nextui-org/button';
import * as React from 'react';
import { Input, InputProps } from '@nextui-org/input';
import { cn } from '@nextui-org/theme';

import { EyeSlashSvg, EyeSvg, KeySvg } from '@/assets/icons';
import { caption } from '@/theme/typography';

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ classNames, ...others }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleToggleShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <Input
        ref={ref}
        classNames={{
          ...classNames,
          innerWrapper: cn('gap-2', classNames?.innerWrapper),
          inputWrapper: cn('border-0 border-b shadow-none', classNames?.inputWrapper),
          description: cn(caption(), 'text-gray-600', classNames?.description),
          errorMessage: cn(caption(), 'text-red-500', classNames?.errorMessage),
        }}
        endContent={
          <Button isIconOnly tabIndex={-1} variant='light' onClick={handleToggleShowPassword}>
            {showPassword ? <EyeSlashSvg className='w-6' /> : <EyeSvg className='w-6' />}
          </Button>
        }
        placeholder='Password'
        startContent={
          <KeySvg
            className={cn('w-6 shrink-0', {
              'text-red-500': others.isInvalid,
            })}
          />
        }
        type={showPassword ? 'text' : 'password'}
        {...others}
      />
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
