import { Button } from '@nextui-org/button';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/dropdown';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BoldChevronDownSvg } from '@/assets/icons';

interface RHFDropdownProps {
  name: string;
  items: { key: string | number; label: string }[];
}

const RHFDropdown: React.FC<RHFDropdownProps> = ({ name, items }) => {
  const { control } = useFormContext<Record<string, Set<string | number>>>();

  return (
    <Controller
      control={control}
      // defaultValue={items[0].key}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Dropdown>
          <DropdownTrigger>
            <Button
              className='bg-neutral-white'
              endContent={<BoldChevronDownSvg className='w-5' />}
              radius='none'
              size='lg'
            >
              {value}
            </Button>
          </DropdownTrigger>

          <DropdownMenu
            aria-label='Dynamic Actions'
            items={items}
            selectedKeys={value}
            selectionMode='single'
            onSelectionChange={(key) => onChange(key)}
          >
            {({ key, label }) => <DropdownItem key={key}>{label}</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
      )}
    />
  );
};

export default RHFDropdown;
