import * as React from 'react';
import { cn } from '@nextui-org/theme';

import FallbackImage from '../Images/FallbackImage/FallbackImage';

import EmptyPng from '@/assets/images/empty-state.png';
import { body } from '@/theme/typography';

interface EmptyProps {
  message?: React.ReactNode;
  className?: string;
}

const Empty: React.FC<EmptyProps> = ({ message, className }) => {
  return (
    <div className={cn('flex flex-col items-center gap-6 self-center text-center', className)}>
      <FallbackImage
        alt='Not found'
        className='h-[300px] w-[300px] rounded-full bg-gray-100'
        height={300}
        src={EmptyPng}
        width={300}
      />

      <div className={body({ weight: 'medium' })}>
        {message ?? (
          <>
            Sorry, we couldn&apos;t find anything matching your search.
            <br />
            Please try again.
          </>
        )}
      </div>
    </div>
  );
};

export default Empty;
