import { Divider } from '@nextui-org/divider';
import { cn } from '@nextui-org/theme';
import { ImageProps } from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { Button } from '@nextui-org/button';

import { FallbackImage, ThumbnailTags } from '..';

import { body, caption, heading } from '@/theme/typography';

interface ThumbnailProps {
  title: string;
  tags?: { id: number; label: string }[];
  href: string;
  description?: string;
  imageUrl: string | string[];
  badge?: React.ReactNode;
  metadata?: {
    key: string | number;
    value: React.ReactNode;
  }[];
  ctaButton?: string;

  variant?: 'light' | 'dark';
  className?: string;
  imageProps?: Omit<Partial<ImageProps>, 'className'>;
  imageWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  classNames?: {
    image?: string;
    title?: string;
    tag?: string;
    tags?: string;
    description?: string;
    createdBy?: string;
    createdAt?: string;
    divider?: string;
    contentWrapper?: string;
    ctaButton?: string;
  };
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  title,
  tags,
  imageUrl,
  href,
  description,
  metadata,
  badge,
  className,
  classNames = {},
  imageProps,
  imageWrapperProps,
  ctaButton,
  variant = 'light',
}) => {
  const filteredMetadata = metadata?.filter(({ value }) => !!value);

  return (
    <div
      className={cn(
        'group flex cursor-pointer flex-col gap-4',
        { 'text-neutral-white': variant === 'dark' },
        className,
      )}
    >
      <div
        className={cn('relative aspect-[42/27] overflow-hidden', classNames.image)}
        {...imageWrapperProps}
      >
        <Link href={href}>
          {!Array.isArray(imageUrl) && (
            <FallbackImage
              alt={title}
              height={300}
              loading='lazy'
              src={imageUrl}
              width={400}
              {...imageProps}
              className={cn(
                'relative h-full w-full bg-gray-100 object-cover',
                'transition-all group-hover:scale-105',
              )}
            />
          )}

          {Array.isArray(imageUrl) && (
            <div className='grid h-full w-full grid-cols-2 grid-rows-2 gap-1'>
              {Array.from({ length: 4 }, (_, index) => imageUrl[index] ?? '').map((url, index) => (
                <div key={index} className='h-full w-full overflow-hidden bg-gray-100'>
                  {url && (
                    <FallbackImage
                      alt={title}
                      height={300}
                      loading='lazy'
                      src={url}
                      width={400}
                      {...imageProps}
                      className={cn('h-full w-full object-cover')}
                    />
                  )}
                </div>
              ))}
            </div>
          )}

          <div className='absolute right-0 top-0 h-full w-full transition-all group-hover:bg-black/20' />
        </Link>

        {badge && <div className='absolute right-4 top-4'>{badge}</div>}
      </div>

      <div className={cn('flex flex-col gap-4', classNames.contentWrapper)}>
        <div className='flex flex-col gap-2'>
          <ThumbnailTags
            className={classNames.tags}
            classNames={{ tag: classNames.tag }}
            tags={tags}
          />

          <Link
            className={cn(heading({ size: 'h5' }), 'line-clamp-2', classNames.title)}
            href={href}
          >
            {title}
          </Link>
        </div>

        {description && (
          <p
            className={cn(
              body(),
              'line-clamp-5',
              { 'text-gray-600': variant === 'light' },
              { 'text-gray-300': variant === 'dark' },
              classNames.description,
            )}
          >
            {description}
          </p>
        )}

        {(metadata?.length ?? 0) > 0 && (
          <div
            className={cn(
              caption(),
              'flex flex-row items-center gap-3',
              { 'text-gray-600': variant === 'light' },
              { 'text-gray-300': variant === 'dark' },
            )}
          >
            {filteredMetadata?.map(({ key, value }, index) => (
              <React.Fragment key={key}>
                {value}

                {index < filteredMetadata.length - 1 && (
                  <Divider
                    className={cn(
                      'h-3',
                      { 'bg-neutral-white': variant === 'dark' },
                      classNames.divider,
                    )}
                    orientation='vertical'
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {ctaButton && (
          <Link href={href}>
            <Button
              className={cn(
                'border-neutral-black',
                'hover:bg-neutral-black hover:text-neutral-white hover:!opacity-100',
                classNames.ctaButton,
              )}
              radius='none'
              size='lg'
              variant='bordered'
            >
              {ctaButton}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Thumbnail;
