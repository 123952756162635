import { AxiosRequestConfig } from 'axios';

import { publicApi } from '@/config/api';
import { Model } from '@/models/model';
import { GetModelsApiResponse } from '@/types/apis/model';

const SERVICE_PATH = '/models';

export const getModels = async ({ params }: { params?: AxiosRequestConfig['params'] }) => {
  try {
    const { data } = await publicApi<GetModelsApiResponse>({
      method: 'GET',
      url: SERVICE_PATH,
      params,
    });

    const models = data.data.map((item) => ({
      id: item.id,
      name: item.name,

      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      publishedAt: new Date(item.publishedAt),
    })) as Model[];

    return models;
  } catch (error) {
    return null;
  }
};
