'use client';

import {
  BreadcrumbItem,
  BreadcrumbItemProps,
  Breadcrumbs as NextUIBreadcrumbs,
  BreadcrumbsProps as NextUIBreadcrumbsProps,
} from '@nextui-org/breadcrumbs';
import { cn } from '@nextui-org/theme';
import * as React from 'react';

import { ChevronRightSvg } from '@/assets/icons';
import { button } from '@/theme/typography';

interface BreadcrumbsProps extends NextUIBreadcrumbsProps {
  items: (Omit<BreadcrumbItemProps, 'children'> & {
    key: string;
    label: string;
  })[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, ...others }) => {
  return (
    <NextUIBreadcrumbs separator={<ChevronRightSvg className='w-5' />} {...others}>
      {items.map(({ key, label, href, classNames, ...others }) => (
        <BreadcrumbItem
          key={key}
          classNames={{
            ...classNames,
            item: cn(button(), classNames?.item),
          }}
          href={href}
          {...others}
        >
          {label}
        </BreadcrumbItem>
      ))}
    </NextUIBreadcrumbs>
  );
};

export default Breadcrumbs;
